var render = function render(){var _vm=this,_c=_vm._self._c;return _c('view-container',{attrs:{"breadcrumbs":"","scroll-fab":""}},[_c('toolbar',{attrs:{"title":_vm.getTitle}},[_c('v-btn',{attrs:{"text":"","small":"","color":"success"},on:{"click":_vm.saveAction}},[_c('v-icon',[_vm._v("mdi-content-save")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"overflow-y-auto px-0 pt-3",style:(_vm.viewHeight(127))},[_c('v-form',{ref:"form",staticClass:"py-5 px-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('form-layout',{attrs:{"layout":_vm.layout},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.$t('general.name', 'Sitrep Name'),"rules":_vm.$getValidationRules([
                  {
                    rule: 'Required',
                    message: 'This input is required',
                    messageKey: 'vaidations.required',
                  },
                ]),"required":"","dense":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})]},proxy:true},{key:"sitrepDate",fn:function(){return [_c('date-selector',{attrs:{"width":300,"label":_vm.$t('sitrep.sitrepDate', 'Sitrep Date'),"rules":_vm.$getValidationRules([
                  {
                    rule: 'Required',
                    message: 'This input is required',
                    messageKey: 'vaidations.required',
                  },
                ]),"required":"","dense":""},on:{"change":function($event){return _vm.validate()}},model:{value:(_vm.item.sitrepDate),callback:function ($$v) {_vm.$set(_vm.item, "sitrepDate", $$v)},expression:"item.sitrepDate"}})]},proxy:true},{key:"tags",fn:function(){return [_c('v-combobox',{attrs:{"label":_vm.$t('sitrep.tags', 'Tags'),"multiple":"","chips":"","append-icon":"mdi-tag-multiple","dense":"","small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"chip--select-multi",attrs:{"small":"","label":"","input-value":data.selected,"disabled":data.disabled,"close":""},on:{"click:close":function($event){return _vm.item.tags.splice(data.index, 1)}}},'v-chip',data.attrs,false),[_vm._v(_vm._s(data.item))])]}}]),model:{value:(_vm.item.tags),callback:function ($$v) {_vm.$set(_vm.item, "tags", $$v)},expression:"item.tags"}})]},proxy:true},{key:"file",fn:function(){return [_c('v-file-input',{attrs:{"label":_vm.$t('sitrep.file', 'File'),"rules":_vm.editMode
                  ? []
                  : _vm.$getValidationRules([
                      {
                        rule: 'Required',
                        message: 'This input is required',
                        messageKey: 'vaidations.required',
                      },
                    ]),"required":"","dense":""},model:{value:(_vm.sitrepFile),callback:function ($$v) {_vm.sitrepFile=$$v},expression:"sitrepFile"}})]},proxy:true}])},[[(_vm.editMode)?_c('v-checkbox',{attrs:{"label":_vm.$t('sitrep.reuploadFile', 'Reupload File'),"dense":""},model:{value:(_vm.item.updateRequest),callback:function ($$v) {_vm.$set(_vm.item, "updateRequest", $$v)},expression:"item.updateRequest"}}):_vm._e()]],2),_c('br'),_c('form-bar',{attrs:{"valid":_vm.valid},on:{"save":_vm.saveAction,"reset":_vm.reset,"validate":function($event){return _vm.$refs.form.validate()}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }