<template>
  <view-container breadcrumbs scroll-fab>
    <toolbar :title="getTitle">
      <v-btn text small color="success" @click="saveAction">
        <v-icon>mdi-content-save</v-icon>
        <span class="text--secondary">{{ $t("general.save") }}</span>
      </v-btn>
    </toolbar>
    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 pt-3" :style="viewHeight(127)">
        <v-form ref="form" v-model="valid" class="py-5 px-5">
          <form-layout :layout="layout">
            <template v-slot:name>
              <v-text-field
                v-model="item.name"
                :label="$t('general.name', 'Sitrep Name')"
                :rules="
                  $getValidationRules([
                    {
                      rule: 'Required',
                      message: 'This input is required',
                      messageKey: 'vaidations.required',
                    },
                  ])
                "
                required
                dense
              />
            </template>
            <template v-slot:sitrepDate>
              <date-selector
                :width="300"
                @change="validate()"
                v-model="item.sitrepDate"
                :label="$t('sitrep.sitrepDate', 'Sitrep Date')"
                :rules="
                  $getValidationRules([
                    {
                      rule: 'Required',
                      message: 'This input is required',
                      messageKey: 'vaidations.required',
                    },
                  ])
                "
                required
                dense
              />
            </template>
            <template v-slot:tags>
              <v-combobox
                v-model="item.tags"
                :label="$t('sitrep.tags', 'Tags')"
                multiple
                chips
                append-icon="mdi-tag-multiple"
                dense
                small-chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    label
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    class="chip--select-multi"
                    close
                    @click:close="item.tags.splice(data.index, 1)"
                    >{{ data.item }}</v-chip
                  >
                </template>
              </v-combobox>
            </template>
            <template v-slot:file>
              <v-file-input
                v-model="sitrepFile"
                :label="$t('sitrep.file', 'File')"
                :rules="
                  editMode
                    ? []
                    : $getValidationRules([
                        {
                          rule: 'Required',
                          message: 'This input is required',
                          messageKey: 'vaidations.required',
                        },
                      ])
                "
                required
                dense
              />
            </template>
            <template v-solt:reuploadFile>
              <v-checkbox
                v-if="editMode"
                v-model="item.updateRequest"
                :label="$t('sitrep.reuploadFile', 'Reupload File')"
                dense
              />
            </template>
          </form-layout>

          <br />
          <form-bar
            :valid="valid"
            @save="saveAction"
            @reset="reset"
            @validate="$refs.form.validate()"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toolbar from "@/common/components/Toolbar";
import FormLayout from "@/common/layouts/FormLayout";
import ViewContainer from "@/common/layouts/ViewContainer";
import FormBar from "@/common/components/FormBar";
import DateSelector from "@/common/components/DateSelector";

export default {
  name: "SitrepForm",
  components: {
    Toolbar,
    ViewContainer,
    FormBar,
    FormLayout,
    // eslint-disable-next-line vue/no-unused-components
    DateSelector,
  },
  data() {
    return {
      sitrepFile: null,
      item: {
        id: 0,
        tags: [],
        updateRequest: false,
      },
      valid: false,
      originalState: { id: 0 },
      layout: [
        {
          type: "row",
          rows: [
            {
              columns: [
                { name: "name", width: 6 },
                { name: "sitrepDate", width: 6 },
              ],
            },
            {
              columns: [
                { name: "file", width: 6 },
                { name: "tags", width: 6 },
              ],
            },
            {
              columns: [{ name: "reuploadFile", width: 6 }],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    Object.assign(this.originalState, this.item);
    this.loadTypes(true);

    if (this.editMode) {
      this.loadSitrep(this.idParam).then(() => {
        this.item = this.getSitrepById(this.idParam);
        this.item.updateRequest = false;
        // this.item = {
        //   ...item,
        //   tags: this.notEmptyArray(this.item.tags)
        //     ? this.item.tags.split(",")
        //     : [],
        // };
      });
    }
  },
  computed: {
    ...mapGetters("ui", ["getLanguage"]),
    ...mapGetters("types", ["getTypesByGroup"]),
    ...mapGetters("sitreps", ["getSitrepById"]),

    idParam() {
      return parseInt(this.$route.params.sitrepId);
    },
    editMode() {
      return this.idParam > 0;
    },
    getTitle() {
      if (this.editMode) {
        return this.$t("sitrep.sitrepForm") + ": " + this.$t("general.edit");
      } else {
        return this.$t("sitrep.sitrepForm") + ": " + this.$t("general.new");
      }
    },
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    isAdmin() {
      return this.$hasRole("ADMIN");
    },

    getDistricts() {
      return this.item.governorate && this.notEmptyArray(this.getAdmin2Areas)
        ? this.getAdmin2Areas.filter(
            (f) => f.admin1PCode === this.item.governorate
          )
        : [
            {
              name: "Please select a governorate to populate districts list",
              pcode: null,
            },
          ];
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),
    ...mapActions("sitreps", ["loadSitrep", "saveSitrep"]),

    saveAction() {
      // const item = { ...this.item, tags: this.item.tags.join(",") };
      this.$uploadDto("/sitrep/save", this.sitrepFile, this.item).then(() => {
        this.$router.go(-1);
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
    validate() {
      setTimeout(() => this.$refs.form.validate(), 500);
    },
  },
};
</script>
